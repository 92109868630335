<template>
  <b-container class="library-filters-section">
    <b-row class="my-5">
      <b-col class="px-md-0" sm="12" md="4">
        <b-form-group class="search-wrapper mb-0">
          <div class="d-flex justify-content-between align-items-center">
            <b-form-input
              v-model.lazy="filters.search"
              :placeholder="
                $t('libraryContent.libraryContentSearchInputPlaceholder')
              "
              class="border-0 search-input"
            />
            <b-button style="background-color: transparent; border: 0;">
              <inline-svg
                :src="require('@/assets/icons/library-search-icon.svg')"
                height="32"
                width="32"
              >
              </inline-svg>
            </b-button>
          </div>
        </b-form-group>
      </b-col>
      <b-col class="px-md-0" sm="12" md="8">
        <div class="ml-auto filter-buttons-wrapper h-100" gutter-2>
          <template v-if="isMentor">
            <b-button
              v-b-modal.add-content-modal
              variant="primary"
              class="px-3"
            >
              <inline-svg
                :src="require('@/assets/icons/library-add-content-white.svg')"
                height="21"
                width="21"
              ></inline-svg>
              <span class="ml-3">
                {{ $t("libraryContent.libraryContentAddContentBtn") }}
              </span>
            </b-button>
            <ShareDropdown
              class="h-100 library-share-button"
              text="Share"
              type="library"
              is-outline
              :url="getLibraryLink"
              :data="{
                title: $t(
                  'libraryContent.libraryContentShareDropdownNameLabel'
                ),
                description: $t(
                  'libraryContent.libraryContentShareDropdownDescriptionLabel'
                )
              }"
            />
          </template>
          <b-dropdown ref="filter_dropdown">
            <template #button-content>
              <inline-svg
                :src="require('@/assets/icons/library-filter.svg')"
                height="21"
                width="21"
              ></inline-svg>
              <span>{{ $t("libraryContent.libraryContentFilters") }}</span>
              <inline-svg
                :src="require('@/assets/icons/chevron-down.svg')"
                height="10"
                width="10"
              ></inline-svg>
            </template>
            <b-dropdown-item
              href="javascript:void(0)"
              :active="selectedType === 'book'"
              @click="() => setType('book')"
            >
              <inline-svg
                :src="require('@/assets/icons/library-book.svg')"
                height="21"
                width="21"
              ></inline-svg>
              <span>{{
                $t("libraryContent.libraryContentFiltersBooksOnly")
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              href="javascript:void(0)"
              :active="selectedType === 'media'"
              @click="() => setType('media')"
            >
              <inline-svg
                :src="require('@/assets/icons/library-media.svg')"
                height="21"
                width="21"
              ></inline-svg>
              <span>{{
                $t("libraryContent.libraryContentFiltersMultimediaOnly")
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              href="javascript:void(0)"
              :active="selectedType === 'document'"
              @click="() => setType('document')"
            >
              <inline-svg
                :src="require('@/assets/icons/library-docs.svg')"
                height="21"
                width="21"
              ></inline-svg>
              <span>{{
                $t("libraryContent.libraryContentFiltersDocumentsOnly")
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isMentor"
              href="javascript:void(0)"
              :active="selectedStatus === 'listed'"
              @click="() => setStatus('listed')"
            >
              <inline-svg
                :src="require('@/assets/icons/library-listed-item.svg')"
                height="21"
                width="21"
              ></inline-svg>
              <span>{{
                $t("libraryContent.libraryContentFiltersListed")
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isMentor"
              href="javascript:void(0)"
              :active="selectedStatus === 'unlisted'"
              @click="() => setStatus('unlisted')"
            >
              <inline-svg
                :src="require('@/assets/icons/library-unlisted-item.svg')"
                height="21"
                width="21"
              ></inline-svg>
              <span>{{
                $t("libraryContent.libraryContentFiltersUnlisted")
              }}</span>
            </b-dropdown-item>
            <b-dropdown-form>
              <b-button
                class="reset-btn"
                variant="primary"
                @click="resetFilters"
              >
                {{ $t("libraryContent.libraryContentFiltersResetFilters") }}
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <AddContentModal />
  </b-container>
</template>

<script>
import Vue from "vue";
import ShareDropdown from "@/components/ShareDropdown.vue";
import AddContentModal from "@/components/Library/AddContentModal";
import { mapState } from "vuex";

export default Vue.extend({
  name: "LibraryFilters",
  components: {
    ShareDropdown,
    AddContentModal
  },
  props: ["isMentor"],
  data() {
    return {
      selectedType: null,
      selectedStatus: null
    };
  },
  computed: {
    ...mapState("library_content", ["filters"]),
    ...mapState("profile", ["profile"]),
    getLibraryLink() {
      const { href } = this.$router.resolve({
        name: "LibraryMentorPage",
        params: { id: this.profile.uuid }
      });
      const url = new URL(href, window.location.origin).href;
      return url;
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit("onFilter");
      },
      deep: true
    }
  },
  created() {
    if (this.filters) {
      this.selectedType = this.filters.type;
      this.selectedStatus = this.filters.status;
    }
  },
  methods: {
    setType(type) {
      this.filters.type = type;
      this.selectedType = type;
    },
    setStatus(status) {
      this.filters.status = status;
      this.selectedStatus = status;
    },
    resetFilters() {
      this.$store.commit("library_content/RESET_FILTERS");
      this.selectedType = null;
      this.selectedStatus = null;
      this.$refs?.filter_dropdown?.hide(true);
    }
  }
});
</script>

<style>
.library-share-button:hover button {
  background-color: #3e85d0 !important;
  color: #fff !important;
}
.library-share-button:hover svg {
  fill: #fff;
  stroke: #fff;
}
</style>
